import React, {useRef} from "react";
import Main from "../static-components/html/Main";
import { Card, Col, Container, Row } from "react-bootstrap";
import SignInButtons from "../components/buttons/SignInButtons";
import FormSubtitle from "../components/text/FormSubtitle";
import VideoPlayer from "../components/video/player/VideoPlayer";
import Jumbotron from "../components/layout/Jumbotron";
import { generateS3Url } from "../libs/utilities";
import { useOnScreen, useScrollStatus } from "../libs/hooksLib";
import { ArrowRepeat, Broadcast, CameraVideoFill, PersonVideo } from "react-bootstrap-icons";

const IndexPage = () => {
  const primaryVideoRef = useRef(null);
  const {scrollStarted} = useScrollStatus();
  const isOnScreen = useOnScreen(primaryVideoRef);

  return (
    <Main>
      <div className="home">
        <Container>
          <Row className="mb-5">
            <Col>
              <Jumbotron className="bg-white pb-5">
                  <Row className="mb-3">
                    <Col xs={12}>
                      <h1 className="text-center"><strong>The best way to find and engage talent</strong></h1>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mb-5">
                    <Col sm={10} md={8} lg={6}>
                      <h5 className="text-center">Interviewing is slow. <br/>CV screening misses talent. <br/><strong>pitchli</strong> is the video interviewing platform that lets everyone tell their story. <br/>Deliver a better candidate experience.</h5>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col sm={6}>
                      <SignInButtons signInText={null} signUpText={"Get started for free"} />
                    </Col>
                  </Row>
              </Jumbotron>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="mb-4">
            <Col>
              <VideoPlayer
                className="mx-auto"
                ref={primaryVideoRef}
                url={generateS3Url("employer_explainer_short.mp4")}
                playing={scrollStarted && isOnScreen}
                originalHeight={1080}
                originalWidth={1920}
                suppressErrors={true}
                />
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="py-3">
            <Col lg={3} md={6}>
              <div className="mb-2">
                <span className="alert alert-complement ms-1 me-3 p-2 rounded-5">
                  <CameraVideoFill className="p-0" size={24}/>
                </span>
                <h4 className="d-inline">Scale talent</h4>
              </div>
              <p>Interview everyone. Don't miss out on talent through flawed screening.</p>
            </Col>
            <Col lg={3} md={6}>
              <div className="mb-2">
                <span className="alert alert-primary ms-1 me-3 p-2 rounded-5">
                  <PersonVideo className="p-0" size={24}/>
                </span>
                <h4 className="d-inline">Get their story</h4>
              </div>
              <p>Go beyond the CV. Assess candidate fit in minutes.</p>
            </Col>
            <Col lg={3} md={6}>
              <div className="mb-2">
                <span className="alert alert-success ms-1 me-3 p-2 rounded-5">
                  <Broadcast className="p-0" size={24}/>
                </span>
                <h4 className="d-inline">Engage</h4>
              </div>
              <p>Tell your story and engage talent with your company.</p>
            </Col>
            <Col lg={3} md={6}>
              <div className="mb-2">
                <span className="alert alert-warning ms-1 me-3 p-2 rounded-5">
                  <ArrowRepeat className="p-0" size={24}/>
                </span>
                <h4 className="d-inline">Circle back</h4>
              </div>
              <p>Automated feedback to candidates, protecting your brand.</p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="mb-5">
            <Col>
              <Jumbotron className="bg-white pb-5">
                  <Row className="mb-3">
                    <Col xs={12}>
                      <h1 className="text-center"><strong>Find your next star team member with pitchli</strong></h1>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col sm={6}>
                      <SignInButtons signInText={null} signUpText={"Get started for free"} />
                    </Col>
                  </Row>
              </Jumbotron>
            </Col>
          </Row>
        </Container>
        <div className="bg-light">
          <Container className="pb-3 pt-4 bg-light">
            <Row>
              <Col lg={4} sm={6} xs={12}>
                <Card className="mb-3">
                  <Card.Body>
                    <FormSubtitle>Pitchli for employers</FormSubtitle>
                      <VideoPlayer 
                        url={generateS3Url("employer_explainer.mp4")}
                        originalHeight={1080}
                        originalWidth={1920}
                        light={generateS3Url("employer_explainer.png")}
                        suppressErrors={true}
                      />
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} sm={6} xs={12}>
                <Card>
                  <Card.Body>
                    <FormSubtitle>Pitchli for candidates</FormSubtitle>
                      <VideoPlayer 
                        url={generateS3Url("candidate_explainer.mp4")}
                        originalHeight={1080}
                        originalWidth={1920}
                        light={generateS3Url("candidate_explainer.png")}
                        suppressErrors={true}
                        />
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} sm={6} xs={12}>
                <Card>
                  <Card.Body>
                    <FormSubtitle>How to record a great pitch</FormSubtitle>
                      <VideoPlayer 
                        url={generateS3Url("setup_tips.mp4")}
                        originalHeight={1080}
                        originalWidth={1920}
                        light={generateS3Url("setup_tips.png")}
                        suppressErrors={true}
                        />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <Row className="mb-5">
            <Col>
              <Jumbotron className="bg-white pb-5">
                  <Row className="mb-3">
                    <Col xs={12}>
                      <h1 className="text-center"><strong>Tell the story of your team</strong></h1>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mb-5">
                    <Col sm={10} md={8} lg={6}>
                      <h5 className="text-center">More than ever interviewing is a two-way process<br/>Record your pitch or upload your brand video<br/>Tell your next star team member why they should join your team</h5>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col sm={6}>
                      <SignInButtons signInText={null} signUpText={"Get started for free"} />
                    </Col>
                  </Row>
              </Jumbotron>
            </Col>
          </Row>
        </Container>
      </div>
    </Main>
  );
}

export default IndexPage