import queryString from "query-string";
import { PitchError } from "../libs/shared/exceptions";
import {DateTime, Duration} from "luxon";
import {appConfig} from "../libs/shared/app-config";

export function addParametersToQueryString(locationSearch, params){
    const queryObj = queryString.parse(locationSearch);
    return queryString.stringify({...queryObj, ...params});
}


export function stringifyLocation(location){
    let retValue = "";
    retValue += location.locality ? location.locality : "";
    retValue += location.postcode ? ", " + location.postcode : "";
    retValue += location.state ? ", " + location.state : "";
    retValue += location.country ? ", " + location.country : "";
    return retValue;
}

export const parseLocationToPicklistObject = (location) => {
    if(location){
        return {
            value: {
                display: location.locality + ", " + location.state + ", " + location.postcode,
                locality: location.locality,
                state: location.state,
                postcode: location.postcode
            },
            label: location.locality + ", " + location.state + ", " + location.postcode
        }
    }else{
        return {}
    }
}

export function parseNameFromApplicantDetails(applicantDetails){
    let name = "";
    if(applicantDetails){
        if(applicantDetails.firstName) name += applicantDetails.firstName;
        if(applicantDetails.lastName) name += " " + applicantDetails.lastName;
    }
    return name;
}

export function checkNullParameters(fncName){
    for(let x=0; x<arguments.length; x++){
        if(!arguments[x] && arguments[x] !== 0 && arguments[x] !== "") {
            throw new PitchError("Missing argument for function: " + fncName + ", arguments were: " + [...arguments].toString());
        }
    }
}

export function parseDuration(milliseconds, periods){
    return Duration.fromMillis(milliseconds).shiftTo(...periods).values;
}

export function formatTimeDifference(td, {periods = ['years', 'months', 'days', 'hours', 'minutes'], round = false, abbreviations = periods}){
    let text = "";
    if(td.error) return td.error;
    for(let x = 0; x < periods.length; x++){
        if(text !== "" && x !== (periods.length - 1) && td[periods[x]]) text += ", ";
        if(text !== "" && x === (periods.length - 1) && td[periods[x]]) text += " and ";
        if(td[periods[x]]) {
            text += (Math.floor(td[periods[x]])) + " ";
            text += Math.floor(Math.abs(td[periods[x]])) === 1 ? abbreviations[x].slice(0, -1) : abbreviations[x];
            if(round) break;
        }
    }
    if(text === "") text = "0 " + periods[periods.length - 1];
    return text;
}

export function formatDateTime(timestamp){
    const dt = DateTime.fromMillis(timestamp);
    return dt.toLocaleString();
}

export function generateRoleTypeDescriptor(roleName){
    if(!roleName) return "Someone ";
    const prefix = roleName.match('^[aieouAIEOU].*') ? "An " : "A ";
    return prefix + roleName.toLowerCase();
}

export function generateS3Url(filename, uploadsBucket = false){
    if(!uploadsBucket) return `https://${appConfig.s3.PUBLIC_RESOURCES_BUCKET}.s3.${appConfig.s3.REGION}.amazonaws.com/${filename}`;
    return `https://${appConfig.s3.BUCKET}.s3.${appConfig.s3.REGION}.amazonaws.com/${filename}`;
}